import { css } from '@emotion/react';
import { palettes } from './colors/palettes';
import { ColorScheme } from './colors/schemes';
import { zIndexes } from './zIndexes';

export const smallDividingGap = '13px';
export const sectionDividingGap = '21px';
export const largeDividingGap = '32px';

export const smallMobileThreshold = '356px';
export const mediumMobileThreshold = '376px';
export const largeMobileThreshold = '426px';

export const sectionDivider = css`
  margin-bottom: ${sectionDividingGap};

  @media (max-width: ${smallMobileThreshold}) {
    margin-bottom: ${smallDividingGap};
  }

  @media (min-width: ${largeMobileThreshold}) {
    margin-bottom: ${largeDividingGap};
  }
`;

export const capitalizedHeader = css`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.75px;
  margin: 16px;
  font-size: 5vw;

  @media (min-width: ${largeMobileThreshold}) {
    font-size: 24px;
    margin: ${largeDividingGap} 16px;
  }
`;

export const fullWidthButtonStyle = (scheme: ColorScheme) => {
  return css`
    display: block;
    width: 100%;
    height: 3em;
    margin: 0.5rem auto;
    background-color: ${scheme.uiElement};
    color: ${palettes.common.white};
    border: none;
    text-transform: uppercase;
    border-radius: 2px;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    &:hover,
    :active {
      background-color: ${scheme.hoverElement};
    }
    &:disabled {
      background-color: ${scheme.disabledElement};
      cursor: default;
      pointer-events: none;
    }
  `;
};

export const outstandingBalanceSectionStyle = css`
  border-bottom: ${palettes.common.paleGrayBorder} solid 1px;
  text-align: center;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1px;
  margin: 0px;

  h1 {
    margin: 5px;
    padding: 3px;
  }

  @media (max-width: ${smallMobileThreshold}) {
    font-size: 15px;
  }

  @media (min-width: ${largeMobileThreshold}) {
    margin: calc(${largeDividingGap} - 21px);
  }
`;

export const recurringStatusSectionStyle = css`
  font-weight: 100;
  letter-spacing: 0.5px;
  text-align: center;
  margin: ${sectionDividingGap};
  line-height: 1.5;
  font-size: 10px;

  @media (max-width: ${smallMobileThreshold}) {
    margin: ${smallDividingGap} 0 7px 0;
  }

  @media (min-width: ${largeMobileThreshold}) {
    margin: ${largeDividingGap} 0 7px 0;
    line-height: 140%;
    font-size: 13px;
  }
`;

export const loadingScreenStyle = css`
  text-align: center;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1px;

  h1 {
    ${capitalizedHeader};
  }
`;

export const paymentPortalLoginFormStyle = css`
  input {
    display: block;
    width: 100%;
    margin: 1rem auto;
    height: 3em;
    padding: 1em;
    background-color: ${palettes.common.inputFormBackground};
    border: none;
    border-radius: 1px;
    letter-spacing: 0.75px;
    font-weight: 600;
    font-size: 15px;

    @media (max-width: ${mediumMobileThreshold}) {
      margin: 0.5rem auto;
    }
  }

  & > input::placeholder {
    color: ${palettes.common.inputFormPlaceholder};
    font-weight: 600;
  }
`;

export const chargesSectionHeader = css`
  margin: ${sectionDividingGap};
  text-align: center;
  letter-spacing: 1px;

  @media (max-width: ${smallMobileThreshold}) {
    margin: ${smallDividingGap} 0.5rem 0.5rem 0.5rem;
  }

  @media (min-width: ${largeMobileThreshold}) {
    margin: ${largeDividingGap} 0.5rem 0.5rem 0.5rem;
  }
`;

export const incorrectDetailsMessage = css`
  text-align: center;
  font-size: 10px;

  @media (min-width: ${largeMobileThreshold}) {
    font-size: 13px;
  }
`;

export const popupWrapperStyle = css`
  position: absolute;
  z-index: ${zIndexes.overlay};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const popupWindowHeaderStyle = css`
  display: flex;
  padding-top: 8px;
  justify-content: center;
  flex-direction: column;
  background: white;
  width: 100%;
  position: sticky;
  top: 0px;
  right: 2%;
  left: 2%;

  @media (min-width: ${largeMobileThreshold}) {
    padding-top: 16px;
  }
`;

export const policyMessageStyle = css`
  margin-top: 8px;

  @media (min-width: ${largeMobileThreshold}) {
    margin-top: 16px;
  }
`;
